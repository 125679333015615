import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import { FaHighlighter } from "react-icons/fa6";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <div className='w-full flex flex-wrap border-b border-gray-200 text-sm text-gray-600 dark:text-gray-300'>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-ssblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-ssblue'} > B </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-ssblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-ssblue'} >I </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-ssblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-ssblue'} > U </button>
        <button tabIndex='-1'
          onClick={() => editor.chain().focus().toggleHighlight({ color: '#ffff00' }).run()}
          className={editor.isActive('highlight') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-ssblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-ssblue'}
        >
          <FaHighlighter className='mx-auto' />
        </button>
    </div>
  )
}

const TipTapArea = ({ html, updateHtml, idx }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight.configure({ multicolor: true }),
      Placeholder.configure({ placeholder: 'Start typing here...' })
    ],
    onUpdate({ editor }) {
      updateHtml(editor.getHTML())
    },
    editorProps: {
      handleDOMEvents: {
        keydown: (view, event) => {
          if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
            event.preventDefault()
            return false
          }
        }
      },
      attributes: {
        class: 'p-2 text-sm focus:outline-none dark:text-gray-100'
      }
    },
    content: html
  })

  useEffect(() => {
    if (idx > 1) { editor?.commands.clearContent(true) }
  }, [idx])

  return (
    <div className="bg-white dark:bg-gray-900 border border-gray-200 rounded-md">
      <MenuBar editor={editor} />
      <EditorContent className='min-h-30' editor={editor} />
    </div>
  )
}

export default TipTapArea

TipTapArea.propTypes = {
  html: PropTypes.string,
  updateHtml: PropTypes.func.isRequired,
  idx: PropTypes.number
}
