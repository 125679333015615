import React, { useEffect, useState } from "react";
import { FiCheckSquare, FiX } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";

const SlideInNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  const removeNotif = (id) => {
    setNotifications((pv) => pv.filter((n) => n.id !== id));
  };

  return (
    <div className="bg-white min-h-[200px] flex items-center justify-center">
      <button
        onClick={() => {
          setNotifications((pv) => [generateRandomNotif(), ...pv]);
        }}
        className="text-sm text-white bg-indigo-500 hover:bg-indigo-600 active:scale-95 transition-all font-medium px-3 py-2 rounded"
      >
        Add notification
      </button>
      <div className="flex flex-col gap-1 w-72 fixed top-2 right-2 z-50 pointer-events-none">
        <AnimatePresence>
          {notifications.map((n) => (
            <Notification removeNotif={removeNotif} {...n} key={n.id} />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

const NOTIFICATION_TTL = 5000;

const useToasts = () => {
  const [toasts, setToasts] = useState([]);

  return {
    toasts,
    setToasts,
    addToast: (kind, text) => {
      setToasts((pv) => [...pv, { id: Math.random(), text, kind }]);
    }
  }
}

const Toasts = ({toasts, setToasts}) => {
  const removeToast = (id) => {
    setToasts((pv) => pv.filter((n) => n.id !== id));
  };

  return <div className="flex flex-col gap-1 w-72 fixed top-2 right-2 z-50 pointer-events-none">
    <AnimatePresence>
      { toasts.map((n) => (
        <Toast removeNotif={removeToast} {...n} key={n.id} />
      ))}
    </AnimatePresence>
  </div>
}

const Toast = ({ text, kind, id, removeNotif }) => {
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      removeNotif(id);
    }, NOTIFICATION_TTL);

    return () => clearTimeout(timeoutRef);
  }, []);

  return (
    <motion.div
      layout
      initial={{ y: -15, scale: 0.95 }}
      animate={{ y: 0, scale: 1 }}
      exit={{ x: "100%", opacity: 0 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
      className={`p-2 flex items-start rounded gap-2 text-xs font-medium shadow-lg text-white ${kind === 'error' ? 'bg-ssred' : 'bg-ssblue'} pointer-events-auto`}
    >
      <FiCheckSquare className=" mt-0.5" />
      <span>{text}</span>
      <button onClick={() => removeNotif(id)} className="ml-auto mt-0.5">
        <FiX />
      </button>
    </motion.div>
  );
};

export { Toasts, useToasts };
