import React, { useEffect, useRef, useState, useReducer, useMemo, useCallback } from 'react'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import Loading from "./Loading";
import { patch, get } from "@rails/request.js";
import { Modal, useModal } from "./Modal";

const ProgressBar = ({ progressPercentage }) => {
  let color = 'bg-sspurple-alt'
  if (progressPercentage > 10 && progressPercentage <= 30) {
    color = 'bg-gradient-to-r from-sspurple-alt to-sspurple'
  } else if (progressPercentage > 30 && progressPercentage <= 50) {
    color = 'bg-gradient-to-r from-sspurple-alt via-sspurple to-ssblue'
  } else if (progressPercentage > 50 && progressPercentage <= 70) {
    color = 'bg-gradient-to-r from-sspurple via-ssblue to-ssblue-alt'
  } else if (progressPercentage > 70 && progressPercentage <= 90) {
    color = 'bg-gradient-to-r from-ssblue via-ssblue-alt to-ssgreen'
  } else if (progressPercentage > 90) {
    color = 'bg-gradient-to-r from-ssblue via-ssgreen to-ssgreen-alt'
  }
  return (
    <div className='h-2 w-full bg-gray-300'>
      <div style={{ width: `${progressPercentage}%` }} className={`h-full ${color}`}> </div>
    </div>
  )
}

export const VideoUploader = ({initialUrl, setUrl}) => {
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  const [uploading, setUploading] = useState(false)
  const [baseMimeType, setBaseMimetype] = useState(null)
  const videoRef = useRef(null)
  const [progressPercentage, setProgressPercentage] = useState(0.0)
  const [file, setFile] = useState(null)
  const { modalOpen, setModalOpen } = useModal()

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      showToast('Error', 'Accepted formats are video files. Max file size is 1GB.', 'error')
    }

    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'video/*', maxFiles: 1, maxSize: 1000000000 }) // 1 gb

  const axiosConfig = {
    onUploadProgress: progressEvent => setProgressPercentage((progressEvent.loaded / progressEvent.total) * 100),
    headers: { 'X-CSRF-TOKEN': csrfToken }
  }

  const reset = () => {
    setFile(null)
    setProgressPercentage(0)
    setUploading(false)
    videoRef.current.src = initialUrl
  }

	const uploadFile = async () => {
    setUploading(true)
    var key = `video-${file.name}`
		const response = await get(`/api/v1/uploads/r2_hash?key=${key}`, {
			responseKind: "application/json",
		})

		if (response.ok) {
      const json = await response.json
      console.log('uploading', json.url)

      var data = new FormData()
      data.append('Content-Type', file.type)
      data.append('Content-Encoding', 'base64')
      data.append('file', file)

      // using xhr because fetch doesnt support progress yet
      const xhr = new XMLHttpRequest();
      const success = await new Promise((resolve) => {
        xhr.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            setProgressPercentage((event.loaded / event.total) * 100)
          }
        });
        xhr.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            console.log("download progress:", event.loaded / event.total);
          }
        });
        xhr.addEventListener("loadend", () => {
          resolve(xhr.readyState === 4 && xhr.status === 200);
          setUploading(false)
          setUrl(json.base_url + '/' + key)
          setModalOpen(false)
        });
        xhr.open("PUT", json.signed_url, true);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.send(file);
      });
    } else {
      console.log('refresh the page. your session is expired')
      setUploading(false)
    }
  }

  useEffect(() => {
    if (!file) { return }

    let mimeType = file.type
    let leMime = mimeType.replace(/\/.*$/, "")
    if (leMime !== 'video') {
      showToast('Error', 'The computer overlords think that the file you are uploading is not a video. Try an MP4, MOV, WEBM. Sorry, it is out of my hands.', 'error')
      setUploading(false)
      return
    }
    videoRef.current.src = URL.createObjectURL(file)
  }, [file])

  return <>
    <video ref={videoRef} className={`h-96 w-96 mx-auto object-cover rounded-md cursor-pointer shadow-lg border border-gray-400 border-dashed border-2 ${!initialUrl && !file ? 'hidden' : ''}`} src={initialUrl} onClick={() => setModalOpen(true)} />
    { !file && !initialUrl && <button className="relative block mt-4 sm:mx-auto w-lg rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => setModalOpen(true)} >
      <img src="https://images.shosay.com/icon-message-speech.png" className={`mx-auto h-16 w-16`} />
      <span class="mt-2 block text-sm font-semibold text-gray-900">Upload Video</span>
    </button> }
    { modalOpen && <Modal maxWidth='max-w-2xl' modalOpen={modalOpen} setModalOpen={setModalOpen} icon='video' title='Upload Video' >
      <div className="relative p-8">
        <div className="sm:text-center">
          <h2 className="text-3xl font-extrabold text-slate-900 tracking-tight sm:text-4xl">Upload Video</h2>
        </div>
        <div className='w-70 sm:w-96 mx-auto mt-20'>
          <video ref={videoRef} controls className={`h-96 w-96 mx-auto object-cover rounded-md shadow-lg border border-2 border-black ${!file ? 'hidden' : ''}`} src={initialUrl} />
          { !file && <div className="bg-gray-50 dark:bg-gray-750 mt-3 relative block min-h-20 w-full border-2 border-gray-300 flex flex-col justify-center cursor-pointer border-dashed rounded-lg p-12 text-center hover:border-gray-400" {...getRootProps()} >
            <input {...getInputProps()} />
            <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
              Click or drop a <b>video</b> file to upload.
            </span>
          </div> }

          { file && <div className='bg-gray-50 w-full mt-2 border-2 rounded-lg border-gray-300 p-2'>
            <div className='mt-2 text-sm text-gray-500 dark:text-gray-400 break-words'>{file.path} - {(file.size / 1000 / 1000).toFixed(2)} MB</div>
            { uploading && <ProgressBar progressPercentage={progressPercentage} /> }
            <div className='mt-2 flex justify-center gap-x-3'>
              <button onClick={reset} className="mt-2 group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-ssbeige px-3 py-2 text-slate-900 border-2 border-black">
                <span className="absolute h-0 w-0 rounded-full bg-ssbeige-alt transition-all duration-300 group-hover:h-56 group-hover:w-32"></span>
                <span className="relative">
                  Reset
                </span>
              </button>
              <button onClick={uploadFile} className="mt-2 group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-ssblue px-3 py-2 text-white border-2 border-black">
                <span className="absolute h-0 w-0 rounded-full bg-sspurple transition-all duration-300 group-hover:h-56 group-hover:w-32"></span>
                <span className="relative">
                  Save
                </span>
              </button>
            </div>
          </div> }
        </div>
      </div>
    </Modal> }
  </>
}
